define("bottomline-execution/serializers/bx/storage", ["exports", "bottomline-execution/serializers/storage", "@ember/object"], function (_exports, _storage, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _storage.default.extend({
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, _id, _requestType) {
      var _this = this;

      var mappedStorages = payload.map(function (json) {
        return {
          id: json.id,
          type: 'storage',
          attributes: {
            averageSalesQty: Math.round((0, _object.get)(json, 'average_sales.quantity')),
            current_material_id: _this._getMaterialId(json),
            RealRunOutDT: (0, _object.get)(json, 'empty_run'),
            RunOutDT: (0, _object.get)(json, 'safety_hit.moment'),
            MaterialID: _this._getMaterialId(json),
            SequenceNb: json.number,
            SiteID: _this._getLocationId(json),
            StorageGroupID: (0, _object.get)(json, 'storage_group_name'),
            usableStorageQty: _this._usableStorageQty(json)
          }
        };
      });
      return {
        data: mappedStorages
      };
    },
    _getMaterialId: function _getMaterialId(storageJson) {
      return storageJson.target_product_id || storageJson.product_id;
    },
    _getLocationId: function _getLocationId(storageJson) {
      return storageJson.site_id || storageJson.depot_id;
    },
    _usableStorageQty: function _usableStorageQty(storageJson) {
      return storageJson.usable && storageJson.usable.quantity;
    }
  });

  _exports.default = _default;
});