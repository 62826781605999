define("bottomline-execution/mixins/trip-stop-material-on-truck", ["exports", "@ember/object/mixin", "@ember/service", "bottomline-execution/utils/grouped-totals", "bottomline-execution/mixins/app-state"], function (_exports, _mixin, _service, _groupedTotals, _appState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create(_appState.default, {
    store: (0, _service.inject)(),
    currentTruckInventory: (0, _service.inject)(),
    tripStopMaterialOnTruck: function tripStopMaterialOnTruck() {
      var _this = this;

      var state = this.get('appState');
      var vehicleIds = [state.get('currentTruck'), state.get('currentTrailer')];
      var targetTruckInventories = this.get('currentTruckInventory').forVehicle(vehicleIds).filterBy('material_id');
      var groupedTargetTruckInventories = (0, _groupedTotals.groupedTotalsFor)(targetTruckInventories, {
        groupBy: 'material_id',
        calculateSumFor: 'currentQty'
      }).map(function (item) {
        var materialId = item.groupId;
        item.set('material', _this.get('store').peekRecord('material', materialId));
        return item;
      });
      return groupedTargetTruckInventories.filterBy('totalCurrentQty');
    }
  });

  _exports.default = _default;
});