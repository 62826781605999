define("bottomline-execution/serializers/bx/order-detail", ["exports", "bottomline-execution/serializers/order-detail", "@ember/object", "@ember/service", "@ember/utils"], function (_exports, _orderDetail, _object, _service, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _orderDetail.default.extend({
    store: (0, _service.inject)(),
    siteSerializer: (0, _object.computed)(function () {
      return this.get('store').serializerFor('site');
    }),
    normalizeQueryResponse: function normalizeQueryResponse(_store, _primaryModelClass, payload, _id, _requestType) {
      var _this = this;

      var includedSites = [];
      var mappedOrderDetails = payload.flatMap(function (json) {
        var mappedSite = _this.siteSerializer.mapResponse(json.site);

        includedSites.push(mappedSite);

        _this.removePotentialConflictingRecord('order', json.id);

        var order = _this.get('store').createRecord('order', {
          id: json.id
        });

        return json.details.map(function (orderDetail) {
          if ((0, _utils.isPresent)(orderDetail.remark)) {
            var orderRemarkId = json.id + '_' + orderDetail.id;

            _this.removePotentialConflictingRecord('order_remark', orderRemarkId);

            order.get('order_remarks').createRecord({
              id: orderRemarkId,
              OrderID: json.id,
              Remark: orderDetail.remark
            });
          }

          var material = _this.get('store').peekRecord('material', orderDetail.product_id);

          return {
            id: orderDetail.id,
            type: 'order-detail',
            attributes: {
              EndDT: Date.parse(json.end_date),
              latitude: json.latitude,
              longitude: json.longitude,
              MaterialID: material.get('id'),
              MaterialNameLong: material.get('MaterialNameLong'),
              MinOrderQty: orderDetail.quantity,
              Name: json.site.name,
              OrderID: json.id,
              OrderDt: json.date,
              SiteID: json.site.id
            },
            relationships: {
              'site': {
                data: {
                  type: 'site',
                  id: mappedSite.data.id
                }
              }
            }
          };
        });
      });
      var includedSitesData = includedSites.mapBy('data');
      var includeSiteRemarksData = includedSites.flatMap(function (s) {
        return s.included;
      });
      return {
        data: mappedOrderDetails,
        included: [].concat(includedSitesData, includeSiteRemarksData)
      };
    },
    removePotentialConflictingRecord: function removePotentialConflictingRecord(type, id) {
      try {
        this.get('store').peekRecord(type, id).unloadRecord();
      } catch (_) {
        /* Deliberately left empty */
      }
    }
  });

  _exports.default = _default;
});