define("bottomline-execution/serializers/bx/site", ["exports", "bottomline-execution/serializers/site", "@ember/object", "@ember/service"], function (_exports, _site, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _site.default.extend({
    store: (0, _service.inject)(),
    siteRemarksSerializer: (0, _object.computed)(function () {
      return this.get('store').serializerFor('site-remark');
    }),
    customerSerializer: (0, _object.computed)(function () {
      return this.get('store').serializerFor('customer');
    }),
    normalizeQueryResponse: function normalizeQueryResponse(_store, _primaryModelClass, payload, _id, _requestType) {
      var _this = this;

      var mappedSites = payload.map(function (json) {
        return _this.mapResponse(json);
      });
      return {
        data: mappedSites.flatMap(function (s) {
          return s.data;
        }),
        included: mappedSites.flatMap(function (s) {
          return s.included;
        })
      };
    },
    mapResponse: function mapResponse(json) {
      var includedSiteRemarks = this.get('siteRemarksSerializer').mapResponse({
        site_id: json.id,
        remarks: json.remarks
      });
      var includedCustomer = this.get('customerSerializer').mapResponse({
        site_id: json.id,
        customer_type: json.customer_type
      });
      var mappedSite = {
        id: json.id,
        type: 'site',
        attributes: {
          Address: json.address,
          City: json.city,
          CountryCode: json.country_code,
          latitude: json.latitude,
          longitude: json.longitude,
          Name: json.name,
          SiteType: json.type,
          Telephone: json.phone_number,
          ZipCode: json.zip_code
        },
        relationships: {
          'customer': {
            data: {
              type: 'customer',
              id: includedCustomer.data.id
            }
          },
          'site_remarks': {
            data: includedSiteRemarks.data.map(function (_ref) {
              var type = _ref.type,
                  id = _ref.id;
              return {
                type: type,
                id: id
              };
            })
          }
        }
      };
      return {
        data: mappedSite,
        included: [].concat(includedSiteRemarks.data, includedCustomer.data)
      };
    }
  });

  _exports.default = _default;
});