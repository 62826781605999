define("bottomline-execution/serializers/bx/customer", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _object.default.extend({
    mapResponse: function mapResponse(json) {
      var mappedCustomer = {
        id: "site-".concat(json.site_id, "-customer"),
        type: 'customer',
        attributes: {
          type: (json.customer_type || "").toLowerCase()
        }
      };
      return {
        data: mappedCustomer
      };
    }
  });

  _exports.default = _default;
});